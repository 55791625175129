import axios from 'axios';

class UserService {
    
    async recovery(data) {

        return await axios.post(process.env.REACT_APP_API_HOST + "/users/forgot-password", data)
            .then((response) => {
                return response;
            }).catch((error) => {
                return error.response;
            });
    }

}

const instance = new UserService();

export default instance;